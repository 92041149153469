import React from "react"
import Img from "gatsby-image"
import {css} from "twin.macro"

const BASE_URL = "https://api.obcocinas.es"

const Image = props => {
  const {
    imageMobile,
    aspectRatioMobile,
    imageTablet,
    aspectRatioTablet,
    objectFit,
    widths,
    styles = {},
    className = '',
  } = props
  let fluid
  const sizes = `(min-width: 1024px) ${widths[2]}, (min-width: 768px) ${widths[1]}, ${widths[0]}`
  const generateImageData = ({ image, aspectRatio }) => {
    // url220
    // url440
    // url880
    // url1760
    // url2000
    // aspectratio
    // base64
    // description
    const imageData = {
      aspectRatio: aspectRatio || image.aspectratio,
      base64: image.base64,
      sizes: sizes,
      src: `${BASE_URL}${image.url880}`,
      srcSet: `${BASE_URL}${image.url220} 220w, ${BASE_URL}${image.url440} 440w, ${BASE_URL}${image.url880} 880w, ${BASE_URL}${image.url1760} url1760w, ${BASE_URL}${image.url2000} 2000w`,
      // srcWebp: image.url880webp,
    }

    return imageData
  }

  if (!imageMobile || imageMobile?.base64 === "") {
    fluid = generateImageData({
      image: imageTablet,
      aspectRatio: aspectRatioTablet,
    })
  } else {
    fluid = [
      generateImageData({ image: imageMobile, aspectRatio: aspectRatioMobile }),
      {
        ...generateImageData({
          image: imageTablet,
          aspectRatio: aspectRatioTablet,
        }),
        media: `(min-width: 768px)`,
      },
    ]
  }
  return (
    <Img
      fluid={fluid}
      alt={imageTablet?.alt || ""}
      // loading="eager"
      backgroundColor="#666666"
      objectFit={objectFit && "contain"}
      className={className}
      style={{
        ...styles,
        width: "100%",
        height: "100%",
      }}
      css={imageRenderingClass}
    />
  )
}

export default Image

const imageRenderingClass = css`
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
`
